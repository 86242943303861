import React, { useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import "./App.css";
import { ZoomMtg } from "@zoomus/websdk";
ZoomMtg.setZoomJSLib("https://source.zoom.us/2.17.0/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load("en-US");
ZoomMtg.i18n.reload("en-US");
function App() {
  let [searchParams] = useSearchParams();
  let navigate = useNavigate();
  // environment files
  var sdkKey = process.env.REACT_APP_ZOOM_SDK_KEY;
  var registrantToken = "";
  var appUrl = process.env.REACT_APP_APP_BASE_URL;
  var apiUrl = process.env.REACT_APP_API_BASE_URL;

  function getSignature(token, slug) {
    fetch(`${apiUrl}/api/liveSession/join?roomSlug=${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res, err) => {
        console.log(err);
        if (!res.ok) {
          throw new Error("You are not allowed to join this session");
        }
        return res.json();
      })
      .then((response) => {
        const { jwtToken, meetingId, passcode, userName, userEmail, userId } =
          response;
        startMeeting({
          jwtToken,
          meetingId,
          passcode,
          userName,
          userEmail,
          slug,
          userId,
        });
      })
      .catch((error) => {
        console.log(error);
        window.location.replace(
          `${appUrl}/meet/${slug}/leave?s=4&m=${encodeURIComponent(
            error?.message
          )}`
        );
      });
  }

  function startMeeting({
    jwtToken,
    meetingId,
    passcode,
    userName,
    userEmail,
    slug,
    userId,
  }) {
    document.getElementById("zmmtg-root").style.display = "block";

    ZoomMtg.init({
      leaveUrl: `${appUrl}/meet/${slug}/leave?s=2`,
      disableInvite: true,
      meetingInfo: [],
      success: (success) => {
        ZoomMtg.join({
          signature: jwtToken,
          meetingNumber: meetingId,
          userName: userName || "",
          sdkKey: sdkKey,
          userEmail: userEmail || "",
          passWord: passcode || "",
          customerKey: userId,
          tk: registrantToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error);
            window.location.replace(
              `${appUrl}/meet/${slug}/leave?s=4&m=${encodeURIComponent(
                error.message
              )}`
            );
          },
        });
      },
      error: (error) => {
        console.log(error);
        window.location.replace(
          `${appUrl}/meet/${slug}/leave?s=4&m=${encodeURIComponent(
            error.message
          )}`
        );
      },
    });
  }
  useEffect(() => {
    const token = searchParams.get("t");
    const slug = searchParams.get("m");
    if (!token) {
      window.location.replace(
        `${process.env.REACT_APP_APP_BASE_URL}/auth/login`
      );
    } else if (!slug) {
      navigate(`/error?m=${encodeURIComponent("Invalid session link")}`);
    } else {
      getSignature(token, slug);
    }
  });
  return (
    <div className="App">
      <main>
        <ThreeDots color="#37d2b8" height={40} width={40} />
      </main>
    </div>
  );
}

export default App;
