import React from "react";
import { useSearchParams } from "react-router-dom";
import "./App.css";

function ErrorPage() {
  let [searchParams] = useSearchParams();
  return (
    <div className="App">
      <main>
        <h1>Looks something wrong</h1>
        <p>{searchParams.get("m")}</p>
      </main>
    </div>
  );
}

export default ErrorPage;
