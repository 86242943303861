import Analytics from "analytics";
import googleTagManager from '@analytics/google-tag-manager'

const analytics = Analytics({
  app: "awesome-app",
  plugins: [
    googleTagManager({
      containerId: "GTM-WBQ2LBH",
    }),
  ],
});

// Expose to window
window.Analytics = analytics

export default analytics;
